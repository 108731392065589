import { Injectable } from '@angular/core';
import { REST_API } from '../constants/api.constants';
import { WidgetUtils } from '../types/widgets/utils';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  services: any = {
    'restorative': [
      { 'value': 'Microneedling RF', 'key': 'microneedling_rf' },
      { 'value': 'Focus Array or Elite+', 'key': 'focus_array' },
      {
        'value': 'Coolsculpting - Fat Reduction',
        'key': 'coolsculpting_fat_reduction',
      },
      { 'value': 'Fibroblast - Plasma Pen', 'key': 'fibroblast_plasma_pen' },
      { 'value': 'FUE Hair Transplants', 'key': 'fue_hair_transplants' },
    ],
    'corrective': [
      {
        'value': 'Tattoo Removal - PicoSure Laser',
        'key': 'tattoo_removal_picosure_laser',
      },
      {
        'value': 'Superficial Vein Treatments',
        'key': 'superficial_vein_treatments',
      },
      { 'value': 'Laser Hair Reduction', 'key': 'laser_hair_reduction' },
      { 'value': 'Skin Tag Removal', 'key': 'skin_tag_removal' },
    ],
    'nourishing': [
      {
        'value': 'Dermaplaning - Exfoliating',
        'key': 'dermaplaning_exfoliating',
      },
      {
        'value': 'Hydrodermabrasion - Deep pore cleaning + serum infusion',
        'key': 'hydrodermabrasion',
      },
      { 'value': 'Hydrating Facials', 'key': 'hydrating_facials' },
      {
        'value': 'Pelleve/Tempsure - Collagen Boosting Facial',
        'key': 'pelleve_tempsure',
      },
    ],
  };
  pageData = new Map<string, Record<string, any>>();

  constructor(private apiService: ApiService) {}

  public async getData(slugs: string[]) {
    const _slugs = [];
    for (const slug of slugs) {
      if (!this.pageData.has(slug)) {
        _slugs.push(slug);
      }
    }
    if (_slugs.length) {
      const response = await this.apiService.post(REST_API.pagesDetails, { 'slugs': _slugs });
      if (response.isSuccess) {
        for (const key of Object.keys(response.data)) {
          const list: Record<string, any> = {};
          for (const item of response.data[key]) {
            const widget = WidgetUtils.parseWidget(item);
            if (widget?.active) list[`${item.slug}_${item.key}`] = widget;
          }
          this.pageData.set(key, list);
          // console.log(this.pageData);
        }
      }
    }
    let widgetData: Record<string, any> = {};
    for (const slug of slugs) {
      widgetData = { ...widgetData, ...this.pageData.get(slug) };
    }
    return widgetData;
  }
}
